<script>
import Layout from '../../layout/Layout'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultMixin from '../mixin'

export default {
  name: 'Page',
  mixins: [DefaultMixin],
  components: {
    Layout
  },
  data () {
    return {
    }
  },
  computed: {
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
  }
}
</script>

<template>
  <layout no-menu menu-style="compact" menu-active="" menu-name="" no-title hfull>
    <router-view />
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
